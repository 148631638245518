import { useMyContext } from "../../contexts/StateHolder";
import { useParams, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import RadiantPlayer from "./RadiantPlayer.js";
import { organization } from "../../config_settings.json";
import { findSubOrganizationSecret, getPlayerConfig } from "../../scripts/dataHandlers";
import { getImageByKey } from "../../scripts/getImageByKey";

const AssetVideoPlayer = (props) => {
    console.log("props", props);
    const { user, chosenItem, loading, setLoading } = useMyContext();
    const { asset, orgId } = useParams();

    const [assetId, setAssetId] = useState("");
    const [companyId, setCompanyId] = useState("");
    const [groupId, setGroupId] = useState("");
    const [organizationId, setOrganizationId] = useState("");
    const [secretKey, setSecretKey] = useState("");
    const [userId, setUserId] = useState(0);

    const [showPlayer, setShowPlayer] = useState("hidden");
    const [campaignID, setCampaignID] = useState(null);
    const history = useHistory();

    useEffect(() => {
        if (organization.organizationId === (orgId || props.organizationId)) {
            // Set organizationId
            setOrganizationId(organization.organizationId);
            // Set companyId
            setCompanyId(organization.companyId);
            // Set groupId
            setGroupId(organization.groupId);
            // Set secretKey
            setSecretKey(organization.key);
            const checkAdvertisementPlayerConfig = async () => {
                const res = await getPlayerConfig();
                console.log(res);
                const campaignId = res?.data?.items?.find((el) => el.title === "player-campaigns-list").value;
                console.log(campaignId);

                if (campaignId && campaignId * 1 > 0) setCampaignID(campaignId);
                console.log(campaignId);

                setLoading(false);
            };
            !props.isEvent && checkAdvertisementPlayerConfig();
        } else {
            // TODO: GET SUBORGANIZATION DATA FROM SOMEWHERE

            const getAllSubOrganization = async () => {
                let subKey = await findSubOrganizationSecret(organization.organizationId, organization.key, orgId);
                const wantedSubOrg = {
                    organizationId: orgId * 1,
                    companyId: 10154,
                    groupId: orgId * 1 + 1,
                    key: subKey?.secret,
                };

                // Set organizationId
                setOrganizationId(wantedSubOrg.organizationId);
                // Set companyId
                setCompanyId(wantedSubOrg.companyId);
                // Set groupId
                setGroupId(wantedSubOrg.groupId);
                // Set secretKey
                setSecretKey(wantedSubOrg.key);
                const checkAdvertisementPlayerConfig = async () => {
                    const res = await getPlayerConfig();
                    console.log(res);
                    const campaignId = res?.data?.items?.find((el) => el?.title === "player-campaigns-list").value;
                    console.log(campaignId);

                    if (campaignId && campaignId * 1 > 0) setCampaignID(campaignId);
                    console.log(campaignId);

                    setLoading(false);
                };
                !props.isEvent && checkAdvertisementPlayerConfig();
            };
            getAllSubOrganization();
        }

        setUserId(user?.userId || 0);
        setAssetId(asset * 1 || props.asset * 1);

        /* 
    If showPlayer is hidden (first time) or wantedHidden (user closed the player),
    turn it to visible as first/new video has been chosen.

    If player is already visible and new one is chosen, make player hidden
    (it will be reactivated in next useEffect)
    */
        if (showPlayer === "hidden" || showPlayer === "wantedHidden") {
            setShowPlayer("visible");
        } else {
            setShowPlayer("hidden");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asset, orgId, user]);

    useEffect(() => {
        // If showPlayer is hidden, turn it visible. If showPlayer is wantedHidden, keep it hidden.
        if (showPlayer === "hidden") {
            setShowPlayer("visible");
        }
    }, [showPlayer]);
    const embedPlayerHandler = () => {
        if (props.isSerie) {
            history.push(`/${props.routes.playSerie}/${orgId}/${asset}`);
        } else {
        }
    };
    if (user && assetId !== "" && organizationId !== "" && showPlayer === "visible" && !loading && (chosenItem || props.isEvent)) {
        return (
            <div className="maxContainerPrimary">
                <div className={`${props.embedPlayer ? "maxContainerEmbed" : "maxContainer"}`}>
                    <RadiantPlayer
                        companyId={companyId}
                        groupId={groupId} // Group of organization
                        organizationId={organizationId} // Id of organization
                        itemId={assetId} // AssetId to play
                        playerAutoStart={props.playerAutoStart}
                        userId={userId} // Id of user, if undefined in token, use zero
                        secretKey={secretKey} // Secret key for organization
                        backRoute={props.backRoute} // Route, where back button takes user. If undefined, it takes automatically to previous route
                        backButton={props.backButton}
                        setShowPlayer={setShowPlayer}
                        campaignIDD={campaignID}
                        isSerie={props.isSerie}
                        googleTrackingId={123444}
                    />
                </div>

                {props.embedPlayer && (
                    <div className="embedButton" onClick={embedPlayerHandler}>
                        <div> Watch</div>
                        <img className="navBarSiteLogo" src={getImageByKey("siteLogo")} title={"Digital"} alt="SiteName" style={props?.styles?.navBar} />
                        <img
                            className="navBarSiteLogoMobile navBarSiteLogoMobileFooter "
                            src={getImageByKey("siteLogo")}
                            title={"Digital"}
                            alt="SiteName"
                            style={props?.styles?.navBar}
                        />
                    </div>
                )}
            </div>
        );
    } else if (user && assetId !== "" && organizationId !== "" && showPlayer === "wantedHidden") {
        return (
            <div className="showPlayerBar">
                <button className="showPlayerBTN" onClick={() => setShowPlayer("visible")}>
                    {"videoPlayer.showPlayer"}
                </button>
            </div>
        );
    } else {
        return null;
    }
};

export default AssetVideoPlayer;
