import React, { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { checkTicketNumber } from "../../../scripts/dataHandlers";
import AssetVideoPlayer from "../../VideoPlayer/AssetVideoPlayer";
import EventsRoomDescription from "./EventsRoomDescription";

import * as classes from "./UpcomingEvent.module.css";

const CompletedEventWithRecording = ({
    routes,
    ticketAccessRequired,
    ticketAccessGranted,

    singleEvent,
    defaultLanguage,
    setTicketAccessGranted,
    recordingAssetId,
    isLiveEvents,

    loading,
}) => {
    const [cookies, setCookie, removeCookie] = useCookies(["tiAcc"]);

    console.warn("recording1", singleEvent);
    const [completedRecordingAssetId, setCompletedRecordingAssetId] = useState(null);

    const checkForVideoAccess = useCallback(async () => {
        const res = await checkTicketNumber(singleEvent?.eventId, cookies?.tiAcc);
        if (res.data.status === "ok") {
            // setCookie('tiAcc', cookies?.tiAcc, { path: '/' });
            // setCompletedRecordingAssetId(singleEvent.rooms[0].recordings[0]?.assetId);

            setCookie("tiAcc", cookies?.tiAcc, {
                path: "/",
            });
            setCompletedRecordingAssetId(singleEvent.rooms[0].recordings[0]?.assetId);
        } else {
            removeCookie("tiAcc", { path: "/" });
        }
    }, []);
    useEffect(() => {
        if (ticketAccessGranted && singleEvent?.eventId && cookies?.tiAcc) {
            checkForVideoAccess();
        }
    }, [ticketAccessGranted, singleEvent?.eventId, cookies?.tiAcc]);
    console.log("recording11", ticketAccessRequired, ticketAccessGranted, completedRecordingAssetId);

    return (
        <>
            {/* // this will work when there is access control applied to recording , wee have prevented a loophole by passing completedRecordingAssetId as a parameter */}
            {ticketAccessGranted && ticketAccessRequired && completedRecordingAssetId && cookies?.tiAcc && !loading && (
                <div className={classes.SecondaryContainer}>
                    <AssetVideoPlayer asset={completedRecordingAssetId} isEvent={true} />
                </div>
            )}
            {/* // will work when no access is applied */}
            {ticketAccessGranted && !ticketAccessRequired && !loading && (
                <div className={classes.SecondaryContainer}>
                    <AssetVideoPlayer asset={recordingAssetId} isEvent={true} />
                </div>
            )}

            {!loading && (
                <EventsRoomDescription
                    liveEvent={isLiveEvents}
                    routes={routes}
                    ticketAccessGranted={ticketAccessGranted}
                    singleEvent={singleEvent}
                    ticketAccess={ticketAccessRequired}
                    defaultLanguage={defaultLanguage}
                    setTicketAccessGranted={setTicketAccessGranted}
                    futureEvents={false}
                />
            )}
        </>
    );
};

export default CompletedEventWithRecording;
