import * as classes from './TOS.module.css';
//import { useTranslation } from 'react-i18next';
import { BrowserRouterasRouter } from 'react-router-dom';
import { getImageByKey } from '../../scripts/getImageByKey';

const TOS = (props) => {
  //const { t } = useTranslation();

  return (
    <div
      className={`${classes.tosContainer} font-300`}
      style={props?.styles?.tosContaine}
    >
      <div
        className={`${classes.tosMainTitle} font-600 ${classes.tosMainTitle_bold_800}`}
      >
        Yleiset toimitus- ja sopimusehdot{' '}
      </div>
      <br />
      <hr />
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        {' '}
        Toimitusehdot ovat voimassa 1.8.2022 alkaen. <br />
        <br />
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400 `}>Yleistä </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Sivustot slamwres.com sekä watch.slamwres.com ovat Slam! Wrestling
        Finland Oy:n sekä L.O.G. Oy:n ylläpitämä ja hallinnoima Internetissä
        toimivia sivustoja. Live-lähetyksiä sekä palvelussa olevia
        kirjastotallenteita voi katsoa osoitteesta watch.slamwres.com.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Palvelu ja siellä näkyvät live-materiaalit, kuvat, tekstit ja logot ovat
        yksinomaisesti Slam! Wrestling Finland Oy:n omaisuutta, ja niitä ei saa
        ilman edellä mainitun tahon yksilöityä lupaa tallentaa, jakaa, levittää
        tai näyttää yksityisesti ja/ tai julkisesti.
      </p>
      <br />
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400 `}>
        Toimitusehdot ja Live-lähetysten katsominen{' '}
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Live-lähetyksiä voi seurata watch.slamwres.com -palvelusta erilaisia
        päätelaitteita käyttämällä. Lähetysten seuranta ja maksaminen tapahtuu
        sivuston kautta.
      </p>
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400 `}>Yhteystiedot:</div>
      <p className={`${classes.tosMainTitle_p} `}>
        Slam! Wrestling Finland Oy
        <br />
        Keskitie 11
        <br />
        00750 Helsini
        <br />
        Sähköposti: info@slamwres.com <br />
        <br />
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        Tilaus sekä tilausvahvistus
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Asiakas rekisteröityy watch.slamwres.com -järjestelmään ja luo sinne
        henkilökohtaisen profiilin (sähköposti sekä salasana). Tilattavat
        tuotteet valitaan kotisivuilla lisäämällä ne ostoskoriin. Tilaus
        lähetetään maksamalla ostoskorin sisältö verkkokaupan kassatoiminnossa.
        Tehdessäsi tilauksen hyväksyt nämä toimitusehdot, tuotteiden hinnat sekä
        toimituskulut. Tilausvahvistus lähetetään sähköpostitse tilaushetkellä
        annettavaan sähköpostiosoitteeseen. Tilausvahvistuksesta ilmenevät
        tilatut tuotteet sekä niiden hinta.
      </p>
      <br />
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Jos asiakkaalle ei syystä tai toisesta tule tilausvahvistusta, tilaus ei
        todennäköisesti ole onnistunut, tai annetussa sähköpostiosoitteessa on
        ollut virhe. Kehotamme tällaisessa tilanteessa ottamaan viivytyksettä
        yhteyttä asiakaspalveluumme info@slamwres.com.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        Sopimuksen syntyminen sekä maksaminen{' '}
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Sopimus syntyy, kun asiakas on kirjautunut järjestelmään ja maksanut
        valitsemansa tuotteen ja/ tai palvelupaketin. Kauppa syntyy tällöin
        verkkokaupan asiakkaan ja verkkokaupan välille. Verkkokaupan vastuulla
        ovat kauppaan liittyvät velvoitteet.{' '}
      </p>
      <p className={`${classes.tosMainTitle_p} `}>
        Verkkokaupan maksuvälittäjänä toimii Visma Pay (Visma Payments Oy,
        y-tunnus 2486559-4, https://www.visma.fi/vismapay/), joka on
        rekisteröity Finanssivalvonnan ylläpitämään maksulaitosrekisteriin.
        Maksamiseen siirrytään Visma Payn verkkopalvelun kautta ja tiliotteella
        ja laskulla maksun saajana näkyy Visma Pay tai Visma Payments Oy.
      </p>
      <br />
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Visma Pay välittää saapuneet maksut eteenpäin kauppiaalle. Maksaminen on
        turvallista, sillä kaikki maksutapahtumaa koskevat tiedot välitetään
        salattua yhteyttä käyttäen niin ettei kukaan ulkopuolinen taho näe
        maksutapahtuman tietoja.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>Maksutavat</div>
      <p className={`${classes.tosMainTitle_p} `}>
        Maksutapa valitaan tilauksen tekemisen yhteydessä. <br />
        <br />
      </p>
      <p className={`${classes.tosMainTitle_p} `}>
        Visma Pay -palvelun kautta voit maksaa verkkopankkitunnuksilla,
        lompakolla, maksukorteilla (credit/ debit), laskulla tai osamaksulla.
        Käytettävissä ovat seuraavat maksutavat: Osuuspankki, Nordea, Danske
        Bank, Oma Säästöpankki, Säästöpankki, Aktia, Paikallisosuuspankit,
        S-Pankki, Handelsbanken, Ålandsbanken, MobilePay, Masterpass, Visa-,
        Visa Debit-, Visa Electron-, MasterCard-, Debit MasterCard -kortit.
        Lisäksi maksutavaksi käy erikseen lunastettu SlamWres-arvoseteli
        (voucher). <br />
        <br />
      </p>
      <p className={`${classes.tosMainTitle_p} `}>
        MobilePay: Voit maksaa MobilePay-lompakollasi, mikäli olet sallinut
        verkkokaupoissa maksamisen sovelluksen asetuksista. MobilePay-lompakolla
        suoritetut maksut veloitetaan lompakkoon liitetyltä maksukortilta.
        Mikäli maksun veloittaminen maksukortilta epäonnistuu,
        MobilePay-lompakolla maksaminen ei ole mahdollista verkkokaupassa.{' '}
        <br />
        <br />
      </p>
      <p className={`${classes.tosMainTitle_p} `}>
        Pivo: Palvelun sekä maksun käyttöehdot ovat tarjolla pivo.fi sivuilla:
        https://pivo.fi/kayttoehdot/pivon-kayttoehdot/. <br />
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Visma Pay -maksupalvelun yhteystiedot
          <br />
          Visma Payments Oy (Y-tunnus 2486559-4)
          <br />
          Sähköposti: payform@bambora.com
          <br />
          Puhelin: 09 315 420 37 (arkisin klo 9–16)
          <br />
          Postiosoite: Brahenkatu 4, 53100 Lappeenranta
          <br />
        </p>
      </p>
      <br />
      <img
        src={'https://static.vismapay.com/pay_banners/row.png'}
        width='100%'
        height={'100px'}
        style={{ minHeight: '100px' }}
      />
      <p className={`${classes.tosMainTitle_p} `}>
        Kaikki maksutavat ovat turvallisia. Verkkomaksut ja luottokorttimaksut
        maksetaan pankkien tai Visma Payn sivujen kautta. Slam! Wrestling
        Finland Oy tai sen yhteistyökumppanit eivät tallenna tai saa tietoonsa
        asiakkaiden tilitietoja. Luottokorttimaksujen tiedot tallentavat Visma
        Pay (Visma Payments Oy). Luottokorttimaksujen varmentamiseen käytetään
        "Verified by Visa" tai "MasterCard SecureCode" -järjestelmiä.
        Luottokorttiveloitus hyväksytään ja korttia veloitetaan tästä johtuen
        tilaushetkellä.
      </p>
      <p className={`${classes.tosMainTitle_p} `}>
        <br />
        Kestotilauksen yhteydessä asiakasta veloitetaan automaattisesti
        luottokortilta kestotilauksen summa kuukausittain. HUOM! Kestotilauksen
        yhteydessä, tilisiirtomahdollisuus ei ole käytössä.{' '}
      </p>
      <p className={`${classes.tosMainTitle_p} `}>
        <br />
        Jos yllä mainituista vaihtoehdoista ei löydy mieleistä, voit maksaa
        laskun ennakkolaskuna (voucherien tilaaminen), joka lähetetään
        sähköpostiisi. Ennakkolaskutettujen tilausten toimitusajan laskeminen
        alkaa siitä, kun asiakas saa hyväksynnän sähköpostitse
        palveluntarjoajalta.{' '}
      </p>
      <p className={`${classes.tosMainTitle_p} `}>
        <br />
        Ennakkolaskulla maksetuista tilauksista Veloitamme laskutuslisän 6,50 €
        per lähetetty lasku. Valitessaan maksutavaksi laskun asiakas vakuuttaa,
        että hänen taloudellinen tilanteensa tilaushetkellä on sellainen, että
        hän kykenee ongelmitta suorittamaan tilatusta palvelusta laskutettavan
        summan ja että hänen tiedossaan ei ole sellaista seikkaa, joka saattaisi
        vaarantaa hänen maksukykynsä. Slam! Wrestling Finland Oy:llä ja/ tai
        L.O.G. Oy:llä on oikeus tarkistaa laskulla maksavan asiakkaan
        luottotiedot. Jos tilaajalla on maksuhäiriömerkintöjä, tulee tilaus
        maksaa aina ennakkoon.
        <br />
        <br />
      </p>
      <div className={`${classes.tosTitle} font-400`}>
        SlamWres-arvosetelit, kampanjakoodit sekä voucherit{' '}
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Arvoseteliä tai voucheria voi käyttää palvelussa maksuvälineenä.
        Arvoseteliä tai voucheria voi käyttää kaikille palvelussa oleville
        normaaleille tuotteille ja tilauksille. Arvoseteliä ei voi käyttää
        mahdollisten lisämaksullisten palvelujen ostamiseen
      </p>
      <br />
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Kampanjakoodit oikeuttavat kampanjan mukaiseen alennukseen kampanjassa
        määritetyistä tuotteista. Kampanjakoodilla ei saa alennusta
        ryhmätilauksista tai lisämaksullisista palveluista. Kampanjakoodia ei
        voi käyttää, jos asiakkaalla on jo tilauksestaan erikseen sovitut
        alennusehdot.
      </p>
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>Hinnat </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Tuotteiden hinnat sisältävät arvonlisäveron.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Tuote myydään tilaamishetkellä verkkokaupan sivulla ilmoitettavalla
        hinnalla. Slam! Wrestling Finland Oy sekä L.O.G. Oy pidättävät oikeuden
        olla myymättä tuotetta hinnalla, joka on esim. teknisestä tai
        inhimillisestä syystä päivittynyt verkkokauppaan olennaisesti väärin.
        Tällaisessa tapauksessa sovelletaan yleisiä sopimusoikeudellisia
        periaatteita. Näissä tapauksissa Slam! Wrestling Finland Oy on
        yhteydessä asiakkaaseen, jolloin myös kaupan purkaminen on mahdollista.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Verkkokaupassa olevat hinnat ovat yksittäisten tuotepakettien
        kampanjahintoja. Ryhmätilausten (esim. voucherit) hinta voi vaihdella
        tilauskohtaisesti.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>Käyttöaika</div>
      <p className={`${classes.tosMainTitle_p} `}>
        Asiakkaan ostama watch.slamwres.com katselupaketti tulee asiakkaan
        käytettäväksi välittömästi, kun verkkokauppaan on tullut vahvistus
        maksujärjestelmältä maksun hyväksymisestä. Käyttäjän on huomioitava,
        että Live-lähetykset ovat saatavilla ja käytettävissä vain siihen
        aikaan, kun ne ovat kalenteriin merkitty. Tämän jälkeen katsottavissa on
        vain kirjastossa olevat erillistallenteet.
        <br />
      </p>{' '}
      <p className={`${classes.tosMainTitle_p} `}>
        <br />
        Kestotilaus on voimassa vähintään kaksi (2) kuukautta tai niin kauan,
        kunnes asiakas sen peruuttaa. <br />
      </p>
      <p className={`${classes.tosMainTitle_p} `}>
        <br />
        Suurempien tilausmäärien (esim. voucherit) käyttö- ja voimassaoloaika
        sovitaan tarjouksen hyväksymisen yhteydessä asiakaspalvelumme kanssa.
        <br />
      </p>
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        Laskun maksaminen suuremmissa tilauksissa <br />
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        <br />
        Suuremmissa tilauksissa (esim. voucherit), lasku lähetetään sähköisesti/
        sähköpostitse asiakkaan tiedoilla hänen antamaansa osoitteeseen. Laskun
        eräpäivä on 14 päivää laskulla ilmoitetusta päiväyksestä.
      </p>
      <p className={`${classes.tosMainTitle_p} `}>
        <br />
        Jos laskua ei suoriteta eräpäivään mennessä, siirretään saatava tällöin
        perittäväksi. Erääntyneiden laskujen maksuajan lykkäykset ja muut
        kyselyt tehdään laskussa annettuihin yhteystietoihin.
        <br />
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>Tilauksen peruutus</div>
      <p className={`${classes.tosMainTitle_p} `}>
        <br />
        Kuluttaja-asiakkailla on oikeus peruuttaa tilauksensa kokonaan tai
        osittain ennen palvelun käytön aloittamista (voucherit). Asiakkaan on
        ilmoitettava tilauksen peruuttamisesta kirjallisesti lähettämällä
        sähköpostia osoitteeseen info@slamwres.com. Jos kuluttaja-asiakas
        peruuttaa tilauksen 14 päivän peruuttamisaikaa noudattaen, on hänen
        tehtävä peruutus ennen palvelun käytön aloitusta. Asiakkaan on näissä
        peruutustapauksissa liitettävä palautukseen mukaan nimi, yhteystiedot
        sekä tilinumero mahdollista maksunpalautusta varten.
      </p>{' '}
      <p className={`${classes.tosMainTitle_p} `}>
        <br />
        Kestotilaus on irtisanottava vähintään viikkoa ennen laskutuskauden
        loppumista, tai sen voimassaolo jatkuu automaattisesti seuraavan
        laskutuskuukauden loppuun.{' '}
      </p>
      <p className={`${classes.tosMainTitle_p} `}>
        <br />
        Yksittäisillä päivä tai viikkotilauksilla ei ole palautusoikeutta.
        Valikoimamme ulkopuolelta asiakkaan pyynnöstä tehdyillä erillisillä
        palveluilla ei ole palautusoikeutta. Palautusoikeutta ei myöskään ole
        tilauksien perusteella valmistettavilla tuotteilla tai palveluilla.
      </p>
      <p className={`${classes.tosMainTitle_p} `}>
        <br />
        Yritysasiakkaiden tuotepalautuksissa ja peruutuksissa noudatamme
        kauppalainsäädäntöä ja hyvää kauppatapaa. Palautuksista ja peruutuksista
        tulee sopia tapauskohtaisesti Slam! Wrestling Finland Oy:n kanssa. Slam!
        Wrestling Finland Oy ei ota vastaan peruutusajan jälkeen tehtyjä
        palautuksia tai peruutuksia, joista ei ole tehty peruutusilmoitusta.{' '}
      </p>
      <p className={`${classes.tosMainTitle_p} `}>
        <br />
        Tilauksen peruutukset ja reklamaatiot ilmoitetaan osoitteeseen
        info@slamwres.com.{' '}
      </p>
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        Asiakkaan maksamien rahojen palauttaminen
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        <br />
        Slam! Wrestling Finland Oy tai L.O.G. Oy palauttaa kuluttaja-asiakkaalle
        rahat, jos hän peruuttaa tilauksensa edellä mainitun mukaisesti osin tai
        kokonaan ennen sen aloittamista. Maksunpalautukset suoritetaan
        maksujärjestelmäpalvelun tarjoajan toimesta. Sähköpostitse ilmoitetuille
        peruutuksille tulee varata 72 h käsittelyaika arkipäivinä
        ruuhkavarauksella.
      </p>{' '}
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>Reklamaatiot </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Verkkokaupalla on lakisääteinen virhevastuu myydyistä tuotteista.
        Palvelun toimimattomuudesta tai muista palvelussa ilmenneistä virheistä
        tulee reklamoida viipymättä asiakaspalveluumme. Pyrimme ensisijaisesti
        aina korjaamaan virheen ja auttamaan asiakasta palvelun käytön suhteen.
      </p>{' '}
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>Rajoitukset</div>
      <p className={`${classes.tosMainTitle_p} `}>
        Emme lähtökohtaisesti myy tuotteita henkilöille, joilla on aikaisemmin
        erääntyneitä Slam! Wrestling Finland Oy:lle tai L.O.G. Oy:lle tai jotka
        toistuvasti peruuttavat tilauksensa tai muutoin käyttävät väärin
        tarjoamiamme palveluita.
      </p>{' '}
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        Yrityksen vastuu ja vastuun rajoitukset
      </div>{' '}
      <p className={`${classes.tosMainTitle_p} `}>
        <br />
        Slam! Wrestling Finland Oy ja/ tai L.O.G. Oy ei vastaa yrityksen
        vaikutusmahdollisuuksien ulkopuolella ilmenevistä ongelmista (esim.
        yksittäisen kuluttajan Internet-operaattorista johtuvista
        Internetyhteyden pätkimisistä tai laitteiston päivityksistä tai
        laitteistojen yhteensopivuusongelmista).
      </p>
      <p className={`${classes.tosMainTitle_p} `}>
        <br />
        Slam! Wrestling Finland Oy ja/ tai L.O.G. Oy pidättävät oikeuden
        toimitusehtojen ja hintojen, sisältöjen sekä aikataulujen muutoksiin.{' '}
      </p>
      <p className={`${classes.tosMainTitle_p} `}>
        <br />
        Slam! Wrestling Finland Oy ja/ tai L.O.G. Oy pidättää oikeuden muuttaa
        toimitus- ja sopimusehtojaan ilmoituksetta.
      </p>
      <p className={`${classes.tosMainTitle_p} `}>
        <br />
        Slam! Wrestling Finland Oy ja L.O.G. Oy toimivat hyvän kauppiastavan
        mukaisesti ja noudattavat toiminnassaan Suomen lakia. Asiakkaan ja Slam!
        Wrestling Finland Oy:n väliset riitatapaukset ratkaistaan ensisijaisesti
        neuvottelemalla ja jos sopimukseen ei päästä, niin Helsingin
        käräjäoikeudessa.
      </p>
      <p className={`${classes.tosMainTitle_p} `}>
        <br />
        Kaikissa toimintaan liittyvissä kysymyksissä ja palautteissa pyydetään
        asiakkaita ottamaan yhteyttä asiakaspalveluumme (info@slamwres.com).
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>Force majeure </div>{' '}
      <p className={`${classes.tosMainTitle_p} `}>
        Slam! Wrestling Finland Oy ja/ tai L.O.G. Oy ei ole vastuussa palvelun
        toimimattomuudesta, viivästyksestä tai estymisestä, mikäli syy tähän on
        yritysten vaikutusmahdollisuuksien ulkopuolella oleva seikka, kuten
        tavarantoimitusten katkokset, estävät sääolosuhteet, luonnonmullistus,
        sota, onnettomuus, epidemia, tuonti- tai vientikielto, viranomaisten
        päätös, liikenteen tai energian jakelun häiriintyminen tai estyminen,
        työtaistelutoimenpide tai muu vastaava tai aiemman myyntiportaan
        toimintaa estävä seikka.
      </p>
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        Asiakasrekisteri ja rekisteriseloste
      </div>{' '}
      <p className={`${classes.tosMainTitle_p} `}>
        Palvelun käyttö vaatii asiakkaalta kirjautumisen watch.slamwres.com
        -palveluun. Taustajärjestelmään tallennetaan tuolloin asiakkaan
        sähköposti sekä omavalintainen sisäänkirjautumiskoodi (salasana).
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Asiakastiedot ovat luottamuksellisia eikä niitä luovuteta Slam!
        Wrestling Finland Oy:n sekä L.O.G. Oy:n ulkopuolisille osapuolille, pois
        lukien mahdolliset maksujärjestelmäpalvelut tai niihin liittyvät
        kysymykset. Sähköpostitiedotusta ja mainontaa voidaan tehdä
        rekisteröityneille asiakkaille palvelussa jo olevista tai sinne
        tulevista uusista tuotteista.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Voucher-myynnin yhteydessä, tallennamme asiakkaan yhteystietojen lisäksi
        yksityisasiakkaiden henkilöllisyystunnuksen, jota voidaan tarvittaessa
        käyttää tavanomaiseen luottokelpoisuuden varmentamiseen ja mahdollisiin
        perintätoimiin. Jos viranomainen epäilee asiakasta esim. epäillyn
        rikoksen vuoksi, voidaan näissä tapauksissa asiakas- ja henkilötietoja
        luovuttaa poliisille tai muille viranomaisille.
      </p>
      <p className={`${classes.tosMainTitle_p} `}>
        <br />
        Palvelussamme saatetaan käyttää evästeitä sivuston tilatietojen
        tallentamiseen. Tallennettavia tilatietoja ovat mm.
        sisäänkirjautumistiedot ja valitun palvelun sisältö. Evästetiedostoihin
        ei tallenneta asiakkaan henkilökohtaisia tietoja.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        Rekisteriseloste{' '}
      </div>{' '}
      <p className={`${classes.tosMainTitle_p} `}>Yhteystiedot:</p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Slam! Wrestling Finland Oy <br />
        (Y-tunnus: 3101790-5)
        <br />
        Keskitie 11
        <br />
        00750 Helsini
        <br />
        Sähköposti: info@slamwres.com
        <br />
        <br />
      </p>
      <p className={`${classes.tosMainTitle_p} `}>
        L.O.G. Oy
        <br />
        (Y-tunnus: 2710994-6)
        <br />
        Ajurintie 19 <br />
        03100 Nummela <br />
      </p>
      <br />
    </div>
  );
};

export default TOS;
