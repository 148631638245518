import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { useMyContext } from "../../../contexts/StateHolder";

import { useParams } from "react-router-dom";
import { getSingleEvent, purchasePackage } from "../../../scripts/dataHandlers";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import queryString from "query-string";
import { ToastContainer, toast } from "react-toastify";
import "moment/locale/fi";
import { useLocation } from "react-router-dom";
import CompletedEvents from "./CompletedEvents";
import FutureEvents from "./FutureEvent";

export default function UpcomingEvent(props) {
    const [singleEvent, setSingleEvent] = useState(null);
    const [defaultLanguage, setDefaultLanguage] = useState(null);
    const [loading, setLoading] = useState(true);

    const { singleEventItem, setTicketQuantity } = useMyContext();
    const { orgId, eventId } = useParams();
    const { user, setGoBackToPrevious } = useMyContext();

    // if the events requires ticket to grant access

    const [ticketAccessRequired, setTicketAccessRequired] = useState(false);
    const [ticketAccessGranted, setTicketAccessGranted] = useState(false);
    const [cookies, setCookie] = useCookies(["tiAcc"]);
    const history = useHistory();
    const location = useLocation();

    // if ticccket has been checked already so we can give error /succcesful message

    // this useEffect will only run when user is redirected from purchase page to here
    useEffect(() => {
        let params = queryString.parse(window.location.search);

        async function purchasePackageData() {
            const purchaseResponse = await purchasePackage(cookies?.userData?.userToken, params);
            console.log(purchaseResponse, "purchaseResponse");
            let ticket;

            console.log(purchaseResponse, purchaseResponse.message, purchaseResponse.errorCode === 27, purchaseResponse.errorCode);
            if (purchaseResponse.message.includes("successful") || purchaseResponse.status === "ok") {
                setTicketAccessGranted(true);
                ticket = purchaseResponse?.tickets[0];
                console.log("ticketnew", ticket);

                toast.success("Purchase Successful");
                ticket && setCookie("tiAcc", ticket, { path: "/" });
            } else if (
                purchaseResponse.errorCode === 27 ||
                purchaseResponse.message.includes("refresh call for order")

                // this occours when user refrsh the page
            ) {
                ticket = purchaseResponse?.tickets[0];
                console.log("ticketrefreh", ticket);
                ticket && setCookie("tiAcc", ticket, { path: "/" });
            } else {
            }

            setLoading(false);
        }

        // for dpo payment
        if (params) {
            if (params?.paymentMethod === "7") {
                purchasePackageData();
            } else if (params?.RETURN_CODE === "0") {
                purchasePackageData();
            } else {
                setLoading(false);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // checks ticket
    useEffect(() => {
        const checkTicket = (eventObj) => {
            setSingleEvent(eventObj);
            setDefaultLanguage(eventObj.defaultLanguage || "en_US");
            if (eventObj?.accessControls?.length > 0) {
                const ticketExists = eventObj?.accessControls.find((el) => el.typeName === "Ticket");
                console.log("ticketExists", ticketExists);
                if (ticketExists) {
                    setTicketAccessRequired(true);
                } else {
                    setTicketAccessRequired(false);

                    setTicketAccessGranted(true);
                }
            } else {
                setTicketAccessRequired(false);
                setTicketAccessGranted(true);
            }
            setLoading(false);
        };
        if (singleEventItem) {
            setSingleEvent(singleEventItem);
            checkTicket(singleEventItem);
        } else {
            const getData = async () => {
                const res = await getSingleEvent(orgId, eventId);

                console.log("res", res);
                checkTicket(res?.event);
            };
            getData();
        }
        return () => {
            setTicketQuantity(1);
        };
    }, [singleEventItem, eventId, orgId]);

    const Renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return !loading ? (
                <CompletedEvents
                    routes={props.routes}
                    singleEvent={singleEvent}
                    ticketAccessRequired={ticketAccessRequired}
                    setTicketAccessGranted={setTicketAccessGranted}
                    ticketAccessGranted={ticketAccessGranted}
                    defaultLanguage={defaultLanguage}
                />
            ) : (
                <></>
            );
        } else {
            return (
                <>
                    <FutureEvents
                        days={days}
                        hours={hours}
                        minutes={minutes}
                        seconds={seconds}
                        completed={completed}
                        routes={props.routes}
                        singleEvent={singleEvent}
                        defaultLanguage={defaultLanguage}
                        setTicketAccessGranted={setTicketAccessGranted}
                        ticketAccessGranted={ticketAccessGranted}
                        ticketAccessRequired={ticketAccessRequired}
                    />
                </>
            );
        }
    };

    return (
        <>
            <ToastContainer />
            <div>
                {!loading && singleEvent && (
                    <>
                        <Countdown date={singleEvent?.startTime} renderer={Renderer} />
                    </>
                )}
            </div>
        </>
    );
}
