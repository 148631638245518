import * as classes from './PrivacyPolicy.module.css';

const PrivacyPolicy = (props) => {
  return (
    <div className={`${classes.tosContainer} font-300`}>
      <div
        className={`${classes.tosMainTitle} font-600 ${classes.tosMainTitle_bold_800}`}
      >
        Tietosuojaseloste{' '}
      </div>
      <br />
      <hr />
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        {' '}
        slamwres.com sekä watch.slamwres.com -sivusto kunnioittaa ja suojelee
        verkkosivuillaan vierailevien henkilöiden, asiakkaidensa sekä
        sidosryhmätyössä toimivien henkilöiden yksityisyyttä. Tältä sivulta
        löydät tietosuojaperiaatteemme, sivujemme käyttöehdot ja
        rekisteriselosteet.
      </p>
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400 `}>
        Tietosuojaperiaatteemme
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Keräämme ja käsittelemme vain tarpeellista tietoa ja poistamme
        vanhentuneet tiedot.
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Käytämme henkilötietoja viestinnässä, asiakaspalvelussa ja palveluiden
        toimittamisessa.
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Henkilötietoja käsitellään keskitetysti joko Slam! Wrestling Finland
        Oy:n tai L.O.G. Oy:n toimistoilla.
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Henkilötietoja käytetään myös luvallisen markkinoinnin sekä mainonnan
        kohdentamiseen.
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Emme lähtökohtaisesti luovuta henkilötietoja yrityksen ulkopuolelle
        kaupallisiin tarkoituksiin ilman asiakkaalta saatavaa erillistä lupaa.{' '}
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Tarkempaa tietoa henkilötietojen käsittelystä ja käyttämisestä sekä
        omista oikeuksistasi löydät alla olevista
        henkilötietorekisteriselosteista.
      </p>{' '}
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400 `}>Evästeet</div>
      <p className={`${classes.tosMainTitle_p} `}>
        Vieraillessasi watch.slamwres.com sivuilla, tallentaa nettiselaimesi
        laitteesi kovalevylle automaattisesti evästeitä. Eväste voi sisältää
        tekstiä, numeroita, päivämääriä, paikkatietoja, ja muuta dataa mutta
        evästeisiin ei tallennu mitään henkilökohtaisia tietoja. Eväste ei
        myöskään ole sovellus, eikä sen avulla voi koneeseesi päästä viruksia
        tai muita haittaohjelmia. Evästeistä ei ole koneellesi minkäänlaista
        haittaa.
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Evästeiden avulla voidaan selvittää sivuston kävijämääriä, tallentaa
        sivustolla tehtyjä valintoja (esim. kieliasetukset), seurata kuinka
        sivustoa käytetään (ns. klikkauspolut) sekä kohdentaa ja hallita
        mainontaa (esim. ei näytetä samoja mainoksia useaan kertaan). Emme
        kuitenkaan seuraa yksittäisen kävijän tietoja. Voimme mahdollisesti
        tilastoida kävijätietoja omaan käyttöön esim. katsomiskäyttäytymisen ja
        maantieteellisen sijainnin perusteella.
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Evästeiden avulla pyrimme parantamaan käyttökokemusta sivuillamme sekä
        tuomaan sivuille mainontaa joka käyttäjää kiinnostaa. Voit esimerkiksi
        nähdä muilla sivuilla vieraillessasi slamwres.com:in mainontaa, koska
        tämäkin on mahdollista evästeiden avulla.
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Mikäli watch.slamwres.com sivuilla vieraileva kävijä ei halua antaa
        slamwres.com:lle edellä mainittuja tietoja evästeiden avulla, voi
        evästeiden käytön kieltää selaimen omista asetuksista.
      </p>{' '}
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400 `}>
        Markkinointirekisteriseloste
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Yhdistetty rekisteriseloste- ja informointiasiakirja
      </p>
      <br />
      <ol className={`${classes.padded} font-300`}>
        <li>Rekisterinpitäjä</li>
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Slam! Wrestling Finland Oy <br />
          Keskitie 11 <br />
          00750 Helsini <br />
          Sähköposti: info@slamwres.com <br /> <br />
          sekä <br /> <br />
          L.O.G. Oy <br />
          (Y-tunnus: 2710994-6) <br />
          Ajurintie 19 <br />
          03100 Nummela <br />
        </p>
        <br />
        <li>Yhteystiedot henkilötietojen käsittelyä koskevissa asioissa</li>
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Slam! Wrestling Finland Oy <br />
          Keskitie 11 <br />
          00750 Helsini <br />
          Sähköposti: info@slamwres.com <br /> <br />
        </p>
        <br />
        <li>Rekisterin nimi</li>
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Slamwres.com laskutus, tiedotus- ja markkinointirekisteri.
        </p>
        <br />
        <li>Henkilötietojen käsittelyn laillinen peruste ja tarkoitus</li>
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Henkilötietolain 8 §:n ja EU:n tietosuoja-asetuksen 2 luvun mukaiset
          yleiset edellytykset.
        </p>{' '}
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Henkilötietojen käsittelyn tarkoitus on viestiä slamwres.com:n
          palvelusta, tapahtumista ja uusista palvelusisällöistä.
        </p>{' '}
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Tarvittavien henkilötietojen käsittely on välttämätön edellytys
          tarkoituksen toteuttamiseksi.
        </p>{' '}
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Henkilötietojen käsittelyn perusteena on suostumus.
        </p>{' '}
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Tietojen tyypillisimmät käyttöalueet ovat:
        </p>{' '}
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Palvelujen kehittäminen, suoramarkkinointi, lahjoituspyynnöt,
          tapahtumakutsut, digimarkkinointi ja etämyynti, asiakas- ja
          mielipidetutkimukset.
        </p>{' '}
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          slamwres.com säilyttää vain palvelun toiminnan ja tietojen
          käyttötarkoitusten kannalta tarpeellisia tietoja, joiden käsittelylle
          on lailliset edellytykset. Tarpeettomaksi muuttuneet tiedot ja tiedot,
          joiden käsittelylle ei enää muutoin ole perustetta, anonymisoidaan tai
          hävitetään tietoturvallisesti.
        </p>{' '}
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Henkilötietoja voidaan käsitellä soveltuvan lainsäädännön sallimin
          tavoin kolmansilla osapuolilla, mukaan lukien markkinointi, etämyynti
          ja markkina- sekä mielipidetutkimukset. Kolmannet osapuolet voivat
          olla mainostoimistoja, painotaloja tai vastaavia yhteistyökumppaneita,
          jotka tukevat rekisterin tarkoituksen toteutumista.
        </p>{' '}
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          slamwres.com:lla on asianmukaiset sopimukset tällaisten kolmansien
          tahojen kanssa henkilötietojen käsittelyä varten.
        </p>{' '}
        <br />
        <li>Rekisterin tietosisältö</li>
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Markkinointirekisterissä voidaan käsitellä seuraavia tietoja: <br />{' '}
          <br />
          Nimitiedot <br />
          Yhteystiedot (sähköposti) <br />
          Osoitelähde (mitä kautta osoite on tullut rekisteriin) <br />
          Yhteydenottojen historiatiedot <br /> <br />
        </p>
        <li>Säännönmukaiset tietolähteet</li>
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Markkinointirekisterin henkilötietoja kerätään eri kanavien kautta
          yhteystietonsa slamwres.com:lle antaneista henkilöistä (esim.
          uutiskirjeen tilaus, kilpailun/ arvontakortin täyttäminen). <br />{' '}
          <br />
        </p>
        <p className={`${classes.tosMainTitle_p} `}>
          Välttämättömien tietojen antaminen on edellä mainituissa markkinoinnin
          vastaanottamisen edellytys. <br /> <br />
        </p>
        <p className={`${classes.tosMainTitle_p} `}>
          Rekisterissä ei tehdä automaattisia päivityksiä. <br /> <br />
        </p>
        <li>Säännönmukaiset tietojen luovutukset</li>
        <p className={`${classes.tosMainTitle_p} `}>
          <br />
          Yritys ei luovuta rekisterissään olevien tietoja ulkopuolisille muihin
          kuin tässä rekisteriselosteessa mainittujen tarkoitusten
          toteuttamiseksi ja lakisääteisten tehtäviensä täyttämiseksi tai
          oikeuksiensa toteuttamiseksi ilman asianomaisen nimenomaista ja
          tietoista suostumusta.
          <br /> <br />
        </p>
        <p className={`${classes.tosMainTitle_p} `}>
          Rekisteröityjen henkilötietoja voidaan käsitellä slamwres.com:n
          markkinoinnin ja etämyynnin toteuttamiseen osallistuvilla
          yhteistyökumppaneilla (esim. postitusfirma) tai mahdollista asiakas-
          tai mielipidetutkimusta varten. Slamwres.com:lla on asianmukaiset
          sopimukset tällaisten kolmansien tahojen kanssa henkilötietojen
          käsittelyä varten
          <br /> <br />
        </p>
        <li>Tietojen siirto EU:n tai Euroopan talousalueen ulkopuolelle</li>
        <p className={`${classes.tosMainTitle_p} `}>
          <br />
          slamwres.com ei luovuta lahjoittaja- ja sidosryhmärekisterissään
          olevia tietoja EU:n tai ETA:n ulkopuolelle.
        </p>
        <br />
        <li>Rekisterin suojauksen periaatteet</li>
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Markkinointirekisteriä ylläpidetään sähköisessä muodossa Icareus Suite
          -järjestelmässä sekä pieniltä osin verkkolevyllä Excel-tiedostoina.
          Järjestelmien tietokannat ja ohjelmistot ovat palveluntuottajan
          palvelimilla Internetissä, johon työasemilta on käyttäjä- ja
          salasanasuojauksin suojattu käyttöliittymä.
        </p>{' '}
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Rekisterin käyttö edellyttää henkilökohtaista käyttäjätunnusta ja
          salasanaa, jonka järjestelmä pakottaa vaihtamaan määräajoin.
          Käyttöoikeus päättyy henkilön siirtyessä pois niistä tehtävistä, joita
          varten hänelle on myönnetty käyttöoikeus. Samalla lukitaan
          käyttäjätunnus.
        </p>{' '}
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Henkilötietoja käsitellään luottamuksellisesti. Rekisterinpitäjä on
          järjestänyt tiloihinsa kulunvalvonnan.{' '}
        </p>{' '}
        <br />
        <br />
        <li> Tarkastusoikeus ja tietojen korjaaminen</li>
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Rekisteröidyllä on oikeus tarkistaa slamwres.com:n rekisterissä olevat
          itseään koskevat tiedot ja pyytää virheellisten tietojen korjaamista.
          Rekisteröity voi esittää tarkastusoikeutta koskevan pyynnön
          henkilökohtaisesti tai kirjallisesti kohdassa 2 mainittuihin
          yhteystietoihin. Tarkastusoikeus toteutetaan n. viikon kuluessa
          pyynnön esittämisestä.
        </p>
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Rekisteriin ei sisälly tietoja, joiden osalta tarkastusoikeutta ei
          voida toteuttaa. Rekisteröity voi esittää tiedon korjaamista milloin
          tahansa rekisteriasioista vastaavalle henkilölle.
        </p>
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Tietoja voidaan oikaista tai täydentää rekisterinpitäjän toimesta tai
          rekisteröidyn vaatimuksesta.
        </p>
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Rekisteröidyllä on oikeus vaatia tietojen käsittelyn rajoittamista ja
          vastustaa tietojen käsittelyä.
        </p>
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Slamwres.com:n rekisterissä olevalla henkilöllä on oikeus kieltää
          antamiensa tietojen käytön suoramarkkinointi tms. tarkoitukseen,
          muuttaa niiden julkaisemista koskevaa suostumustaan sekä muutoinkin
          turvautua henkilötietolaissa turvattuihin oikeuksiinsa. Mikäli henkilö
          ei toivo slamwres.com:lta lainkaan postia tai yhteydenottoja, hän voi
          kieltää sen ottamalla kohdassa 2 mainittuun osoitteeseen.
        </p>
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Slamwres.com:n poistaa tarpeettomat henkilötiedot rekisteristä
          rekisteröidyn pyynnöstä.
        </p>
        <br />
        <p className={`${classes.tosMainTitle_p} `}>
          Rekisteröidyllä on oikeus tehdä valitus tietosuojaviranomaiselle.
        </p>
        <br />
        <li>Tietojen säilyttäminen</li>
        <p className={`${classes.tosMainTitle_p} `}>
          Tietoja säilytetään toistaiseksi, niin kauan kuin ne ovat
          tarpeellisia. Tarpeettomaksi muuttuneet tiedot ja tiedot, joiden
          käsittelylle ei enää muutoin ole perustetta, hävitetään
          tietoturvallisesti.{' '}
        </p>
      </ol>
      <br />
    </div>
  );
};

export default PrivacyPolicy;
