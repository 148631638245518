import React from "react";
import LiveVideoPlayer from "../../VideoPlayer/LiveVideoPlayer";
import * as classes from "../Event/UpcomingEvent.module.css";
import EventsRoomDescription from "../Event/EventsRoomDescription";
import { useCookies } from "react-cookie";

export default function LiveEvents({ routes, ticketAccessGranted, ticketAccessRequired, singleEvent, defaultLanguage, setTicketAccessGranted }) {
    console.log(ticketAccessRequired, ticketAccessGranted, singleEvent);
    const [cookies] = useCookies(["tiAcc"]);

    return (
        <>
            {ticketAccessGranted && cookies?.tiAcc && ticketAccessRequired && (
                <div className={classes.SecondaryContainer}>
                    {/* // this will work when there is access control applied to recording , wee have prevented a loophole by passing completedRecordingAssetId as a parameter */}
                    <LiveVideoPlayer
                        channelServiceId={singleEvent?.isMultiroom ? singleEvent.rooms[0].serviceId : singleEvent?.serviceId}
                        eventPlayer={true}
                        eventId={singleEvent.eventId}
                    />
                </div>
            )}
            {/* // will work when no access is applied */}

            {ticketAccessGranted && !ticketAccessRequired && (
                <div className={classes.SecondaryContainer}>
                    <LiveVideoPlayer
                        channelServiceId={singleEvent?.isMultiroom ? singleEvent.rooms[0].serviceId : singleEvent?.serviceId}
                        eventPlayer={true}
                        eventId={singleEvent.eventId}
                    />
                </div>
            )}

            <EventsRoomDescription
                liveEvent={true}
                routes={routes}
                ticketAccessGranted={ticketAccessGranted}
                singleEvent={singleEvent}
                ticketAccess={ticketAccessRequired}
                defaultLanguage={defaultLanguage}
                setTicketAccessGranted={setTicketAccessGranted}
            />
        </>
    );
}
