import React from 'react';
import { useEffect, useState } from 'react';
import { getSeries } from '../../../../scripts/dataHandlers';
import { useMyContext } from '../../../../contexts/StateHolder';
import RenderItem from '../../../ViewAssets/RenderItems/RenderItem';
import * as classes from './ListAllSeries.module.css';
import Carousel from 'react-multi-carousel';
import CategoryTitle from '../../CategoryTitles/CategoryTitle';
import CategoryTitle2 from '../../CategoryTitles/CategoryTitle2/CategoryTitle2';

export default function ListAllCategories({ settings, styles, ...props }) {
  const components = {
    CategoryTitle,
    CategoryTitle2,
  };
  const { organizationId, key, language, user } = useMyContext();

  const {
    slickSettings,
    routes,
    itemImageComponent,
    itemTitleComponent,
    assetProperty,
    categoryTitleComponent,
  } = settings;

  const [allSubCategories, setAllSubCategories] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const res = await getSeries(
        organizationId,
        key,
        language,
        user,
        assetProperty
      );

      setAllSubCategories(res);
    };
    organizationId && key && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, user]);
  const CategoryTitleComponent = components[categoryTitleComponent];

  return (
    <div className={classes.allSeriesContainer}>
      {allSubCategories && allSubCategories.length > 0 && (
        <div className={`${classes.ListAllCategories} font-500`}>
          <CategoryTitleComponent
            id={'123456789098765421'}
            title={props.titleName}
            routes={routes}
            item={null}
            showSerieViewAll={false}
            isSerie={true}
            showTitle={true}
          />
          <Carousel {...slickSettings} infinite={false}>
            {allSubCategories.map((item) => {
              item.isSerie = true;
              item.series = item.groupItems;
              item.serieId = item.id;
              item.listSerieItem = true;
              return (
                <RenderItem
                  key={`${item.id}`}
                  item={item}
                  routes={routes}
                  pushRoute={`/${routes.serieRoute}/${organizationId}/${item.id}`}
                  itemImageComponent={itemImageComponent}
                  itemTitleComponent={itemTitleComponent}
                  // textStyle={{ textAlign: 'center' }}
                  renderCategory={true}
                  styles={styles}
                  showPlayIcon={true}
                  hidePlayButton={true}
                  hideDescription={true}
                  showPublishedDate={true}
                />
              );
            })}
          </Carousel>
        </div>
      )}
    </div>
  );
}
