import React, { useEffect, useState } from 'react';
import EventsItem from '../Events/EventsItem';

import { getUpcomingEvents } from '../../../scripts/dataHandlers';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import EventsItem1 from '../Events/EventsItem1/EventsItem1';

export default function UpcomingEvents(props) {
  const [allUpcomingEvents, setAllUpcomingEvents] = useState(null);
  const { t } = useTranslation();

  // if we need to show events from sub-organization
  const { subOrganizationId } = useParams();

  const { limit } = props.settings;

  console.log(subOrganizationId, props.organizationId);

  useEffect(() => {
    const runFunction = async () => {
      try {
        const res1 = await getUpcomingEvents(
          subOrganizationId ? subOrganizationId : props.organizationId,
          limit,
          null,
          null,
          props.isPastEvents
        );

        if (res1.data.status === 'ok') {
          setAllUpcomingEvents(res1.data.events);
        }
      } catch (error) {
        console.log(error);
      }
    };

    props.organizationId && runFunction();
  }, [props.organizationId, limit, subOrganizationId]);
  return (
    // If it includes props(upcomingEventCategory) , it means it wont have any category id or name and we have to fetch filter data and route accordingly

    allUpcomingEvents &&
    allUpcomingEvents.length > 0 && (
      <EventsItem
        allEvents={allUpcomingEvents}
        heading={props.heading || t('eventsCategory.upcomingEventsTitle')}
        upcomingEventCategory={true}
        organizationId={
          subOrganizationId ? subOrganizationId : props.organizationId
        }
        homepageContainer={props.homepageContainer}
        extraClassname={props.extraClassname}
        hideViewAll={props.hideViewAll}
        styles={props.styles}
        accessedbyAdmin={props.accessedbyAdmin}
      />
    )
  );
}
