import { useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { checkTicketNumber } from "../../../scripts/dataHandlers";
import Button from "../../../shared/Button/Button";

import * as classes from "./UpcomingEvent.module.css";

const BuyTickets = ({ routes, futureEvents, singleEvent, setTicketAccessGranted, hideBuyButton }) => {
    const history = useHistory();
    const { t } = useTranslation();

    const [ticketNum, setTicketNum] = useState("");
    const [cookies, setCookie, removeCookie] = useCookies(["tiAcc"]);

    const buyNewTicket = () => {
        console.log("singleEvent", singleEvent);
        // we need to send also organizationId to this packages

        return history.push(`/${routes.packages}/${singleEvent?.groupId - 1}/event/${singleEvent.eventId}`);
    };

    const checkTicketHandler = async () => {
        const res = await checkTicketNumber(singleEvent.eventId, ticketNum);
        console.log(2);
        console.log(res);
        if (res.data.status === "ok") {
            setTicketAccessGranted(true);
            setCookie("tiAcc", ticketNum, { path: "/" });
        } else if (res.data.status === "error") {
            setTicketNum("");
            removeCookie("tiAcc", { path: "/" });

            if (futureEvents) {
                return toast.error(`${t("eventsCategory.eventNotStarted")}`, {
                    autoClose: 9000,
                    position: "top-right",
                    closeOnClick: true,
                    draggable: true,

                    hideProgressBar: true,

                    theme: "colored",
                });
            }
            return toast.error(`${t("eventsCategory.invalidTicket")}`, {
                autoClose: 9000,
                position: "top-right",
                closeOnClick: true,
                draggable: true,

                hideProgressBar: true,

                theme: "colored",
            });
        }
    };

    return (
        <>
            <ToastContainer />

            <div className={classes.buyTicket}>
                <div className={classes.buyTickets_info}>
                    <div>{t("eventsCategory.enterTicket")}</div>

                    <div className={classes.buyTickets_info_input}>
                        <input
                            type="text"
                            value={ticketNum}
                            onChange={(e) => setTicketNum(e.target.value)}
                            placeholder={`${t("eventsCategory.enterYourTicket")}`}
                        />
                    </div>
                </div>

                <div className={classes.buyTickets_info_button}>
                    <Button onClick={checkTicketHandler} inverse={true}>
                        {" "}
                        {t("eventsCategory.watch")}
                    </Button>

                    {!hideBuyButton && <Button onClick={buyNewTicket}>{t("eventsCategory.buyTickets")}</Button>}
                </div>
            </div>
        </>
    );
};

export default BuyTickets;
